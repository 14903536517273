<template>
  <Layout>
    <template #header>
      <div class="app-header">
        <a-page-header class="page-header" @back="() => $router.go(-1)">
          <template slot="backIcon">
            <a-icon type="arrow-left" /> 返回
          </template>
          <template slot="title">
            <editable-cell :text="name" @change="updateName($event)" />
          </template>
          <template slot="extra">
            <div v-if="active" class="opts">
              <a-button
                :loading="previewLoading"
                @click="preview"
                type="button"
                class="opt"
              >
                <span>手机预览</span>
              </a-button>
              <a-button
                :loading="publishLoading"
                @click="publish"
                type="primary"
                class="opt"
              >
                发布上线
              </a-button>
            </div>

            <template v-else>
              <a-button @click="setActive" type="button" class="opt">
                <span>设为首页</span>
              </a-button>
              <a-button @click="save" type="primary">
                保存
              </a-button>
            </template>
          </template>
        </a-page-header>
      </div>
    </template>
    <a-spin :spinning="spinning">
      <Design />
    </a-spin>
  </Layout>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import Layout from "@/layout/index.vue";
import Design from "@/design/index.vue";
import EditableCell from "@/components/editable-cell.vue";

const PageSvc = require("@/service/page");

import loadDataMixin from "@/mixins/loadData";
import loadDiscountMixin from "@/mixins/loadDiscount";

import { upgrade } from "@/utils/toolbar";

export default {
  name: "PageDetail",

  data() {
    return {
      spinning: true,

      id: 0,
      name: "",
      active: false
    };
  },
  computed: {
    ...mapState({
      desginData: state => state.design,
      oldDesignData: state => state.oldDesignData,

      previewLoading: state => state.button.previewLoading,
      publishLoading: state => state.button.publishLoading
    }),

    hasChanged() {
      const { list, fix } = this.desginData;
      let changed = false;
      if (this.oldDesignData) {
        changed = this.oldDesignData !== JSON.stringify({ list, fix });
      } else {
        changed = list.length > 0 || Object.keys(fix).length > 0;
      }

      return changed;
    }
  },

  mixins: [loadDataMixin, loadDiscountMixin],

  methods: {
    ...mapMutations(["setDesignData", "updateOldDesignData"]),

    async save() {
      try {
        const designData = this.$store.state.design;
        const { list, fix } = designData;
        const res = JSON.stringify({ list, fix });
        await PageSvc.edit(this.id, res);

        this.$message.info("保存完成");

        this.updateOldDesignData(designData);
      } catch (err) {
        console.error(err);
        this.$message.error("保存失败，错误：" + err.message);
      }
    },

    async updateName(name) {
      try {
        await PageSvc.updateName(this.id, name);
        this.name = name;
      } catch (err) {
        console.error(err);
        this.$message.error("修改页面名称失败，错误：" + err.message);
      }
    },

    async setActive() {
      this.$confirm({
        title: "操作提示",
        content: "确认覆盖首页内容吗？此操作无法撤消，请谨慎操作。",

        onOk: () => {
          this._setActive();
        },
        onCancel() {}
      });
    },
    async _setActive() {
      if (this.hasChanged) {
        this.$message.info("请先保存");
        return;
      }

      try {
        await PageSvc.setActive(this.id);
        this.active = true;
      } catch (err) {
        console.error(err);
        this.$message.error("设为首页失败，错误：" + err.message);
      }
    },

    async preview() {
      this.$root.$emit("preview");
    },
    async publish() {
      this.$root.$emit("publish");
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.hasChanged) {
      this.$confirm({
        title: "操作提示",
        content: "您有内容未保存，确认要离开吗？",

        onOk: () => {
          next();
        },
        onCancel() {
          next(false);
        }
      });
    } else {
      next();
    }
  },

  async mounted() {
    try {
      this.spinning = true;

      const id = parseInt(this.$route.params.id);
      const data = await PageSvc.getData(id);
      this.id = id;
      this.name = data.name;
      this.active = data.active;

      if (data.res) {
        let res = JSON.parse(data.res);

        // 自动升级数据，兼容旧记录
        upgrade(res);

        this.setDesignData(res);

        // 记录用于判断变更保存提示
        this.updateOldDesignData(res);

        // 监听首页数据保存
        this.$root.$on("home-data-saved", () => {
          const designData = this.$store.state.design;
          this.updateOldDesignData(designData);
        });
      } else {
        this.setDesignData(null);
        this.updateOldDesignData(null);
      }

      this.spinning = false;
    } catch (err) {
      console.error(err);
      this.$message.error("未正确加载页面数据，如果页面正常请忽略");

      this.setDesignData(null);
      this.updateOldDesignData(null);

      this.spinning = false;
    }
  },

  components: {
    Layout,
    Design,
    EditableCell
  }
};
</script>

<style lang="scss"></style>
