<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-input
        :value="value"
        @change="handleChange"
        @pressEnter="check"
        :maxLength="20"
      />
      <a-icon type="check" class="editable-cell-icon-check" @click="check" />
      <a-icon type="close" class="editable-cell-icon-close" @click="close" />
    </div>
    <div v-else class="editable-cell-text-wrapper">
      {{ value || "" }}<span class="editable-cell-text-placeholder"></span>
      <a-icon type="edit" class="editable-cell-icon" @click="edit" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: String
  },

  data() {
    return {
      value: this.text,
      editable: false
    };
  },

  watch: {
    text(to) {
      this.value = to;
    }
  },

  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit("change", this.value);

      this._value = this.value;
    },
    close() {
      this.editable = false;

      // 取消恢复
      this.value = this._value;
    },
    edit() {
      this.editable = true;

      // 暂存起来，取消时恢复
      this._value = this.value;
    }
  }
};
</script>

<style>
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 54px;
}

.editable-cell-text-wrapper {
  padding: 0px 24px 0px 5px;
  /* padding: 5px 24px 5px 5px; */
}

.editable-cell-text-placeholder {
  width: 6px;
  height: 1em;
  display: inline-block;
}

.editable-cell-icon-check {
  position: absolute;
  right: 26px;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon-close {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check,
.editable-cell-icon-close {
  line-height: 38px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
